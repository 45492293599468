<template>
   <!--about page-->
      
        <!--about us-->
      <section class="about" v-if="aboutData">
        <div class="container">
          <div class="content">
            <div class="row">
              <div class="col-lg-6">
                <img class="img-fluid" src="/../images/bgs/contact.jpg" alt="">
              </div>
              <div class="col-lg-6">
                 <div class="main-title wow slideInUp" data-wow-duration="1.3s" data-wow-delay=".3s"><span class="top-title">{{ $t('about_subtitle') }}</span>
                  <h2>{{aboutData.about.title}}</h2>
                </div>
                <div class="about-text wow slideInUp" data-wow-duration="1.3s" data-wow-delay=".4s">
                  <p>{{ $filters.stripHtml(aboutData.about.description) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--about us-->
      <section class="about-in-page">
        <div class="container">
          <!-- <div class="main-title wow slideInUp" data-wow-duration="1.3s" data-wow-delay=".3s"><span class="top-title">{{ $t('about_subtitle') }}</span>
            <h2>Building a healthy community for all.</h2>
          </div> -->
          <div class="content">
            <!-- <div class="about-text wow slideInUp" data-wow-duration="1.3s" data-wow-delay=".4s">
              <p>We care for the growing needs of our community. We build systems for providing health services for individuals, families, communities and populations in general.</p>
            </div> -->
            <div class="about-boxs">
              <div class="row">
                <div class="col-lg-4 col-12 col">
                  <div class="single-box">
                    <div class="icon"><img class="img-fluid" src="/../images/icons/book.png" alt=""></div>
                    <div class="number"><span>6500<sup>+</sup></span></div>
                    <div class="text">
                      <h3>{{ $t('worldwide_branches') }}</h3>
                      <!-- <p>Bring best quality health services to ones who are in need worldwide.</p> -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-12 col">
                  <div class="single-box">
                    <div class="icon"><img class="img-fluid" src="/../images/icons/folder.png" alt=""></div>
                    <div class="number"><span>1000<sup>+</sup></span></div>
                    <div class="text">
                      <h3>{{ $t('patience_cured') }}</h3>
                      <!-- <p>Bring best quality health services to ones who are in need worldwide.</p> -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-12 col">
                  <div class="single-box">
                    <div class="icon"><img class="img-fluid" src="/../images/icons/placeholder.png" alt=""></div>
                    <div class="number"><span> 300<sup>+</sup></span></div>
                    <div class="text">
                      <h3>{{ $t('local_partners') }}</h3>
                      <!-- <p>Bring best quality health services to ones who are in need worldwide.</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--about page-->
      <!--video-->
      <section class="video-in-page" style="background-image:url('../images/bgs/vid-bg.jpg')" v-if="aboutData">
        <div class="container">
          <div class="content">
            <div class="vid-icon"><a :href="aboutData.video.video_link" data-fancybox="gallery"> <i class="bi bi-play-fill"></i></a></div>
            <div class="vid-text">
              <p>{{aboutData.video.title}}</p>
              <h2>{{aboutData.video.description}}</h2>
            </div>
          </div>
        </div>
      </section>
      <!--video-->
    <!--clients-->
      <section class="clients" v-if="aboutData">
        <div class="container">
          <div class="content">
            <!-- <div class="clients-carousel owl-carousel owl-theme"> -->
              <Carousel  :items-to-show="3" :wrap-around="true">
                <slide v-for="client in aboutData.clients" :key="client.id">
                   <img class="img-fluid" v-lazy="client.image" alt="client logo">
                </slide>
              </Carousel>
            <!-- </div> -->
          </div>
        </div>
      </section>
      <!--clients-->
</template>

<script>
import { mapState } from "vuex";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default {
  name: 'About',
  components: { 
    Carousel,
    Slide,
    Pagination,
    Navigation,
    }, 
    computed: {
		...mapState(["aboutData"])
    },
    created() {
      this.$store.dispatch("getAboutData");
    },
}
</script>
